@import "../../../styles/2-tools/tools.color-square.scss";
@import "../../../styles/2-tools/tools.spacing-mixins.scss";

.HeadingCard {
	width: 100%;
	margin: var(--spacing--xl) auto var(--spacing--md) auto;
	max-width: var(--width-md);

	&___noTopMargin {
		margin-top: 0;
	}

	&___noBottomMargin {
		margin-bottom: 0;
	}
}

.HeadingCard_wrapper {
	background-color: var(--color-white);
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding: var(--spacing--lg);
	@include color-square;
}

.HeadingCard_heading {
	margin-top: var(--spacing--xs);
}

.HeadingCard_text {
	max-width: none;
	padding: var(--spacing--sm) 0;
}
